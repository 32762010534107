import { theme as coreTheme } from '@chakra-ui/core';

// Let's say you want to add custom colors
export const theme = {
  ...coreTheme,
  colors: {
    ...coreTheme.colors,
    base: '#F5F5F5',
    lightGray: '#dddddd',
    mediumGray: '#b0b0b0',
    blue: {
      '50': '#EDF6F8',
      '100': '#C9E4EC',
      '200': '#A6D2E0',
      '300': '#82C1D3',
      '400': '#5FAFC7',
      '500': '#3C9EBB',
      '600': '#32829A',
      '700': '#276577',
      '800': '#1C4855',
      '900': '#112C33',
    },
    primary: {
      '50': '#404E33',
      '100': '#404E33',
      '200': '#404E33',
      '300': '#404E33',
      '400': '#404E33',
      '500': '#404E33',
      '600': '#404E33',
      '700': '#404E33',
      '800': '#404E33',
      '900': '#404E33',
    },
    secondary: '#E5EEC5',
    lightYellow: '#FCF7ED',
    beige: {
      '50': '#FEFCF8',
      '100': '#FCF6EB',
      '200': '#FAF0DE',
      '300': '#F8EBD1',
      '400': '#F6E5C4',
      '500': '#F4E0B7',
      '600': '#C8B896',
      '700': '#9C8F75',
      '800': '#6F6654',
      '900': '#433E32',
    },
    yellow: {
      '50': '#FBF5EB',
      '100': '#F3E3C5',
      '200': '#ECD19F',
      '300': '#E4BF78',
      '400': '#DDAD52',
      '500': '#D69B2C',
      '600': '#B07F25',
      '700': '#89631D',
      '800': '#624714',
      '900': '#3B2B0C',
    },
    disabled: '#9B9B9B',
    circleNavigationActive: '#979797',
    pink: {
      '50': '#FCF6F8',
      '100': '#F6E4EB',
      '200': '#F0D2DE',
      '300': '#EAC0D1',
      '400': '#E4AEC4',
      '500': '#DE9CB8',
      '600': '#B68097',
      '700': '#8E6476',
      '800': '#654754',
      '900': '#3D2B33',
    },
    green: {
      '50': '#F8F9F6',
      '100': '#EAEEE6',
      '200': '#DCE3D6',
      '300': '#CED8C5',
      '400': '#C0CDB5',
      '500': '#B2C3A5',
      '600': '#92A088',
      '700': '#727D6A',
      '800': '#51594B',
      '900': '#31362D',
    },
    circleNavigation: '#D8D8D8',
    textPrimary: '#4A4A4A',
    darkGray: '#5B5959',
    red: {
      ...coreTheme.colors.red,
      '50': '#E36776',
    },
    error: {
      '50': '#F6E4EB',
      '100': '#E36776',
      '200': '#E36776',
      '300': '#E36776',
      '400': '#E36776',
      '500': '#E36776',
      '600': '#E36776',
      '700': '#E36776',
      '800': '#E36776',
      '900': '#E36776',
    },
    pooGreen: '#7A7C00',
    gray: {
      ...coreTheme.colors.gray,
      '50': '#b0b0b0',
      '100': '#b0b0b0',
      '900': '#fafafa',
    },
  },
  fonts: {
    ...coreTheme.fonts,
    heading: 'Brandish, Times New Roman',
    body: 'Montserrat, Arial',
  },
  fontSizes: {
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '36px',
    '5xl': '48px',
    '6xl': '64px',
  },
};
