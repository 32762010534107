import * as React from 'react';
import { ThemeProvider, CSSReset, Flex, Heading, Stack } from '@chakra-ui/core';
import { Global, css } from '@emotion/core';
import { LogoWithText } from 'components/Logo';
import { theme } from './theme/chakraTheme';

export const Root = () => (
  <ThemeProvider theme={theme}>
    <CSSReset />
    <Global
      styles={css`
        * {
          font-family: 'Montserrat', Arial;
        }
        html,
        body,
        #root {
          height: 100%;
        }
        @font-face {
          font-family: Brandish;
          font-weight: 400;
          font-style: normal;
          src: url('/brandishregular.ttf') format('truetype');
          font-display: swap;
        }
        button {
          touch-action: none;
        }
      `}
    />
    <Flex
      height="100vh"
      width="100vw"
      justifyContent={['center']}
      alignItems={['center']}
      bg="blue.500"
      flexDirection={['column']}
      p={8}
    >
      <Stack direction="column" spacing={8}>
        <Flex justifyContent={['center']}>
          <LogoWithText />
        </Flex>
        <Flex justifyContent={['center']}>
          <Heading
            color="yellow.100"
            maxWidth={['100%', '50%']}
            textAlign="center"
            fontSize={['md', 'xl', '3xl']}
          >
            Mama&apos;s doing some maintenance work! We&apos;ll be back online
            real soon!
          </Heading>
        </Flex>
      </Stack>
    </Flex>
  </ThemeProvider>
);
